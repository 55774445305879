"use strict";

$(document).ready(function () {
  /* Components */
  $(function () {
    // Initialize Swiper
    $('.js-swiper-container').each(function () {
      new Swiper('.swiper-container', {
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        navigation: {
          prevEl: '.swiper__btn-prev',
          nextEl: '.swiper__btn-next'
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 1199px
          1199: {
            slidesPerView: 2
          }
        }
      });
    }); // Scripts for filters

    $('.js-filter').each(function () {
      var form = $(this);
      $('.js-checked-box').on('change', function () {
        var checkBox = $(this);

        if (checkBox.is(':checked')) {
          var checkboxId = checkBox.attr('id');
          var checkedLabel = $('.js-checked-label[for="' + checkboxId + '"]');
          var checkedLabelValue = checkedLabel.html();
          var checkboxDataAttr = checkBox.attr('data-outcoming-data-id');
          var inputField = $('.js-input-field[data-input-field-id="' + checkboxDataAttr + '"]');
          inputField.html(checkedLabelValue);
          form.submit();
        }
      });
    }); // Add the content after clicking on the button

    $('body').on('click', '.js-append-data-btn', function () {
      var btn = $(this);
      var data = {};
      var url = btn.attr('data-append-data-url');
      var pageNumber = btn.attr('data-page-number');
      data.page = pageNumber;
      sendData(data, url, $(this));
    }); //Send data

    function sendData(data, url, button) {
      $.ajax({
        type: 'GET',
        url: url,
        data: data,
        dataType: 'json',
        processData: true,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function success(data) {
          if (data.appendContent) {
            if (data.appendContent.number) {
              var nextPageNumber = data.appendContent.number;
              button.attr('data-page-number', nextPageNumber);
            } else {
              button.css('display', 'none');
            }

            var name = data.appendContent.name;
            var content = data.appendContent.content;
            var block = $('.js-append-data-content[data-append-data-name="' + name + '"]');
            block.append(content);
          }
        },
        error: function error(data) {
          alert("Error!");
        }
      });
      event.preventDefault();
      return false;
    } // Scripts that to add reviews


    $('.js-form').each(function () {
      var rater = $('#rater');
      var raterId = rater.attr('id');
      var input = $('.js-input-rater[data-id-rater="' + raterId + '"]');
      var myRater = raterJs({
        element: document.querySelector('#rater'),
        starSize: 34,
        step: 0.5,
        rateCallback: function rateCallback(rating, done) {
          this.setRating(rating);
          done();
          input.val(rating);
        }
      });
      $('.js-form').submit(function (event) {
        var form = $(this);
        var isError = false; // Check required fields

        form.find('.js-required').each(function () {
          if ($(this).val().length === 0) {
            $(this).addClass('incorrect');
            isError = true;
          }
        });
        form.find('.js-input-rater').each(function () {
          if ($(this).val().length === 0) {
            alert("Please rate the casino!");
            isError = true;
          }
        }); // Don't send if any required field is not filled

        if (isError) {
          return false;
        } // Prepare data to send


        var formData = {};
        form.find('input, textarea, select').not('[type=submit]').each(function () {
          formData[$(this).attr('name')] = $(this).val();
        }); // Send

        $.ajax({
          type: form.attr('method'),
          url: form.attr('action'),
          data: formData,
          processData: true,
          context: $(this),
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          success: function success(data) {
            if (data.refresh) {
              window.location.reload();
            }
          },
          error: function error() {
            alert("Error!");
          }
        });
        event.preventDefault();
        return false;
      }); // Remove incorrent class when user is typing

      $('.js-required').on('input propertychange', function () {
        if ($(this).val().length > 0 && $(this).val() != $(this).attr('placeholder')) {
          $(this).removeClass('incorrect');
        }
      }); // Don't send form when enter is clicked in the input

      $('.js-form-input').on('keydown', function (e) {
        if (e.keyCode == 13) {
          e.preventDefault();
          return false;
        }
      });
    });
  });
});